.readable-content {
  display: block;
  position: absolute;
  inset: 0;
  z-index: 11;
}

.post-paragraph.look-up .readable-content {
  pointer-events: none;
}

.post-paragraph.look-up .readable-child {
  transition: opacity 1s ease, filter 1s ease;
  pointer-events: none;
}

.post-paragraph .readable-child {
  transition: opacity 0.6s ease, filter 1s ease;
  transition-delay: 0.5s;
}

.post-paragraph.look-up .readable-child {
  opacity: 0.3;
  filter: blur(4px);
}

.glossary-word {
  position: relative;
  z-index: 5;
  line-height: 1.8;
  letter-spacing: 0px;
  font-style: italic;
  font-family: Georgia, serif;
  outline: 0px;
  border-radius: 3px;
  height: fit-content;
  width: fit-content;
  max-width: fit-content;
  cursor: pointer;
  /* overflow: hidden; */
  @apply text-background;
}

.glossary-word::after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  z-index: -1;
  @apply bg-accent;
  padding: 12px 0px;
}

.glossary-word.look-up-button {
  visibility: hidden;
}

.post-paragraph.look-up .glossary-word.readable-child {
  opacity: 0.6;
  transition: none;
}

.post-paragraph.look-up .glossary-word:not(.readable-child) {
  z-index: 10;
  pointer-events: all;
}

.glossary-lookup {
  position: absolute;
  inset: 0px;
}

.post-paragraph.look-up .glossary-lookup {
  display: block;
}

.glossary-lookup .filler-text {
  opacity: 0;
}

.post-paragraph.look-up .glossary-lookup .glossary-word {
  opacity: 1;
}

.glossary-lookup-content {
  position: relative;
  z-index: 1;
}

.glossary-lookup-content::before {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-10%);
  z-index: -1;
}

.glossary-lookup-content::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(10%);
  z-index: -1;
}

.lookup-content__letter {
  opacity: 0;
  filter: blur(3px);
  animation: fadeIn 0.25s ease forwards;
}

.lookup-content__letter.dash {
  transform: translateX(-100%);
  display: inline-block;
  opacity: 1 !important;
  animation: translateXIn 0.5s ease forwards;
}

@keyframes fadeIn {
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

@keyframes translateXIn {
  100% {
    transform: translateX(0%);
    filter: blur(0px);
  }
}

.filler-text {
  opacity: 0;
  user-select: none;
  pointer-events: none;
}
