:root {
  --base-mono-hue: 0, 0%;
  --base-accent: 56, 100%, 83%;

  --foreground: hsla(var(--base-mono-hue), 93%, 1);
  --muted-foreground: hsla(var(--base-mono-hue), 63%, 1);
  --background: hsla(var(--base-mono-hue), 9%, 1);
  --shiny-background: hsla(var(--base-mono-hue), 12%, 1);
  --border: hsla(var(--base-mono-hue), 30%, 1);
  --accent: hsla(var(--base-accent), 1);
  --dark: hsla(var(--base-mono-hue), 2%, 1);

  --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
