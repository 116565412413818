/* custom */
@import "./css/variables.css";
@import "./css/typography.css";
@import "./css/animation.css";

/* tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: X, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @apply bg-background text-foreground;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
